import React from "react";
import { Helmet } from "react-helmet";
import Img from "gatsby-image";
import { graphql } from 'gatsby';
import Slide from 'react-reveal/Slide';
import Fade from 'react-reveal/Fade';
import Zoom from 'react-reveal/Zoom';
import Roll from 'react-reveal/Roll';
import Layout from "../components/layout";

const parallax = {
    backgroundImage: 'url(../recugHAagLDA7uRYd.jpg)',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
};

const paraOne = {
    backgroundImage: 'url(../recdjeiGgrKdONzGe.jpg)',
    height: '50vh',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover'
};

const paraTwo = {
    backgroundImage: 'url(../recN2r4XNwuASVU1y.jpg)',
    height: '70vh',
    backgroundPosition: 'bottom',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover'
};

const credit = {
    background: 'linear-gradient(rgba(166, 25, 46, 0.45), rgba(166, 25, 46, 0.45)), url(../recDdlyyGbgge4hsI.jpg)',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundPosition: '50%',
    height: '50vh',
}

export default ({ data }) => (
    <div>
        <Layout>
        <Helmet>
            <title>{data.meta.data.story} | Oberlin Photo Stories</title>
            
            <meta name="title" content={data.meta.data.story} />
            <meta name="description" content={data.meta.data.description} />
            
            <meta property="og:type" content="website" />
            <meta property="og:url" content={data.meta.data.metaurl} />
            <meta property="og:title" content={data.meta.data.story} />
            <meta property="og:description" content={data.meta.data.description} />
            <meta property="og:image" content="https://photo-stories.oberlin.edu/recugHAagLDA7uRYd.jpg" />
            <meta property="og:image:width" content="2000" />
            <meta property="og:image:height" content="1333" />
            <meta property="og:image:alt" content={data.meta.data.alt} />
            <meta property="fb:app_id" content="1571317633107125" />
            
            <meta property="twitter:card" content="summary_large_image" />
            <meta property="twitter:url" content={data.meta.data.metaurl} />
            <meta property="twitter:title" content={data.meta.data.story} />
            <meta property="twitter:description" content={data.meta.data.description} />
            <meta property="twitter:image" content="https://photo-stories.oberlin.edu/recugHAagLDA7uRYd.jpg" />
            <meta property="twitter:image:alt" content={data.meta.data.alt} />
        </Helmet>
        {/* hero */}
        <header>
            <div className="wrapper">
                <div style={parallax} className="hero parallax" role="img" aria-label={data.meta.data.alt}></div>
                <div className="title text-center">
                    <h1 className="display-3 text-light">{data.title.data.title}</h1>
                    <a href="#ss" aria-label="Skip to main content"><span className="fas fa-chevron-down fa-3x mt-5 text-white"></span></a>
                </div>
            </div>
        </header>

        <main>
            <div className="anchor" id="ss"></div>
            <div className="container my-4 story">
                {/* intro */}
                <section>
                <Fade>
                <div className="col-lg-10 text-center mx-auto lead mt-5" dangerouslySetInnerHTML={{
                __html: data.intro.data.text.childMarkdownRemark.html,
                }} />
                </Fade>
                </section>
                {/* Johnny Coleman */}
                <section>
                <Slide left>
                <div className="mt-5 text-center" dangerouslySetInnerHTML={{
                __html: data.rec53LvzAhOPcvMim.data.text.childMarkdownRemark.html,
                }} />
                <div className="mt-2 text-center" dangerouslySetInnerHTML={{
                __html: data.recP18OBMUprIGl8U.data.text.childMarkdownRemark.html,
                }} />
                <div className="mt-5" dangerouslySetInnerHTML={{
                __html: data.recC80LQbomuCLTsh.data.text.childMarkdownRemark.html,
                }} />
                </Slide>
                </section>
            </div>
            <section>
            <div style={paraOne} role="img" aria-label={data.recdjeiGgrKdONzGe.data.alt} className="parallax"></div>
            </section>
            <div className="container mb-4 story">
                <section>
                <div className="mt-2" style={{fontSize:`90%`}} dangerouslySetInnerHTML={{
                __html: data.recdjeiGgrKdONzGe.data.text.childMarkdownRemark.html,
                }} />
                <Fade>
                <div className="my-5" dangerouslySetInnerHTML={{
                __html: data.recRmJDn4GDeP4jeq.data.text.childMarkdownRemark.html,
                }} />
                </Fade>
                <div className="row">
                    <div className="col-lg col-12">
                        <Zoom>
                        <div>
                            <div>
                                <Img fixed={data.recxJ57Zw66DGj62V.data.file.localFiles[0].childImageSharp.fixed} alt={data.recxJ57Zw66DGj62V.data.alt} className="w-100" />
                            </div>
                            <div className="mt-2" style={{fontSize:`90%`}} dangerouslySetInnerHTML={{
                            __html: data.recxJ57Zw66DGj62V.data.text.childMarkdownRemark.html,
                            }} />
                        </div>
                        </Zoom>
                    </div>
                    <div className="col-lg col-12">
                        <Zoom>
                        <div>
                            <div>
                                <Img fixed={data.rec1ljtlkWeLlNIaS.data.file.localFiles[0].childImageSharp.fixed} alt={data.rec1ljtlkWeLlNIaS.data.alt} className="w-100" />
                            </div>
                            <div className="mt-2" style={{fontSize:`90%`}} dangerouslySetInnerHTML={{
                            __html: data.rec1ljtlkWeLlNIaS.data.text.childMarkdownRemark.html,
                            }} />
                        </div>
                        </Zoom>
                    </div>
                </div>
                </section>

                {/* Julia Christensen */}
                <section className="mt-5">
                <Slide left>
                <div className="mt-5 text-center" dangerouslySetInnerHTML={{
                __html: data.recXWUvWeUXvQo4zy.data.text.childMarkdownRemark.html,
                }} />
                <div className="mt-2 text-center" dangerouslySetInnerHTML={{
                __html: data.rec6Gh0DX4zW1NF4Q.data.text.childMarkdownRemark.html,
                }} />
                </Slide>

                <Fade>
                <div>
                <Img fluid={data.recpWuIxg2LGbUjI3.data.file.localFiles[0].childImageSharp.fluid} alt={data.recpWuIxg2LGbUjI3.data.alt} className="mt-5" />
                <div className="mt-2" style={{fontSize:`90%`}} dangerouslySetInnerHTML={{
                __html: data.recpWuIxg2LGbUjI3.data.text.childMarkdownRemark.html,
                }} />
                </div>
                <div>
                <Img fluid={data.recvWC5WK8VuXTefD.data.file.localFiles[0].childImageSharp.fluid} alt={data.recvWC5WK8VuXTefD.data.alt} className="mt-5" />
                <div className="mt-2" style={{fontSize:`90%`}} dangerouslySetInnerHTML={{
                __html: data.recvWC5WK8VuXTefD.data.text.childMarkdownRemark.html,
                }} />
                </div>
                <div>
                <Img fluid={data.recOrYYk9AGpGFTBE.data.file.localFiles[0].childImageSharp.fluid} alt={data.recOrYYk9AGpGFTBE.data.alt} className="mt-5" />
                <div className="mt-2" style={{fontSize:`90%`}} dangerouslySetInnerHTML={{
                __html: data.recOrYYk9AGpGFTBE.data.text.childMarkdownRemark.html,
                }} />
                </div>
                </Fade>
                </section>

                {/* Kristina Paabus */}
                <section>
                <Slide left>
                <div className="mt-5 text-center" dangerouslySetInnerHTML={{
                __html: data.recgSPOL9WmumrL1k.data.text.childMarkdownRemark.html,
                }} />
                <blockquote className="my-5 pullquote">
                <p className="mb-0">{data.recgNHzb1UksEvLs3.data.title}</p>
                <footer className="pullquote-footer">{data.recgNHzb1UksEvLs3.data.text.childMarkdownRemark.rawMarkdownBody}</footer>
                </blockquote>
                </Slide>

                <div className="row">
                    <Slide bottom>
                    <div className="col-lg-4 col-12 mb-3">
                        <Img fixed={data.recJsNKR1Fsct8w5j.data.file.localFiles[0].childImageSharp.fixed} alt={data.recJsNKR1Fsct8w5j.data.alt} className="w-100" />
                        <div style={{fontSize:`90%`}} dangerouslySetInnerHTML={{
                            __html: data.recJsNKR1Fsct8w5j.data.text.childMarkdownRemark.html,
                            }} />
                    </div>
                    <div className="col-lg-4 col-12 mb-3">
                        <Img fixed={data.recbhnZ79sJb5UNW0.data.file.localFiles[0].childImageSharp.fixed} alt={data.recbhnZ79sJb5UNW0.data.alt} className="w-100" />
                        <div style={{fontSize:`90%`}} dangerouslySetInnerHTML={{
                            __html: data.recbhnZ79sJb5UNW0.data.text.childMarkdownRemark.html,
                            }} />
                    </div>
                    <div className="col-lg-4 col-12 mb-3">
                        <Img fixed={data.reciXH91PNmx095aP.data.file.localFiles[0].childImageSharp.fixed} alt={data.reciXH91PNmx095aP.data.alt} className="w-100" />
                        <div style={{fontSize:`90%`}} dangerouslySetInnerHTML={{
                            __html: data.reciXH91PNmx095aP.data.text.childMarkdownRemark.html,
                            }} />
                    </div>
                    </Slide>
                </div>
                </section>

                {/* Mimi Kato */}
                <section>
                <Slide left>
                <div className="mt-5 text-center" dangerouslySetInnerHTML={{
                __html: data.rec3OuT8NchYf46zc.data.text.childMarkdownRemark.html,
                }} />
                <div className="mt-5 text-center" dangerouslySetInnerHTML={{
                __html: data.rec3OwCZIGFPmXRNa.data.text.childMarkdownRemark.html,
                }} />
                </Slide>
                </section>
            </div>

            <section>
            <div style={paraTwo} role="img" aria-label={data.recN2r4XNwuASVU1y.data.alt} className="parallax"></div>
            </section>

            <div className="container mb-4 story">
                <section>
                <div className="mt-2" style={{fontSize:`90%`}} dangerouslySetInnerHTML={{
                __html: data.recN2r4XNwuASVU1y.data.text.childMarkdownRemark.html,
                }} />

                <div className="mt-5" dangerouslySetInnerHTML={{
                __html: data.recYkHML3kTyEG9bz.data.text.childMarkdownRemark.html,
                }} />
                <Roll left>
                <div>
                <Img fluid={data.reccsbPzKH6fZGaA0.data.file.localFiles[0].childImageSharp.fluid} alt={data.reccsbPzKH6fZGaA0.data.alt} />
                <div className="mt-2" style={{fontSize:`90%`}} dangerouslySetInnerHTML={{
                __html: data.reccsbPzKH6fZGaA0.data.text.childMarkdownRemark.html,
                }} />
                </div>
                <div>
                <Img fluid={data.recTxUtLrlpf2rzw4.data.file.localFiles[0].childImageSharp.fluid} alt={data.recTxUtLrlpf2rzw4.data.alt} />
                <div className="mt-2" style={{fontSize:`90%`}} dangerouslySetInnerHTML={{
                __html: data.recTxUtLrlpf2rzw4.data.text.childMarkdownRemark.html,
                }} />
                </div>
                </Roll>
                </section>

                {/* Omid Shekari */}
                <section>
                <Slide left>
                <div className="mt-5 text-center" dangerouslySetInnerHTML={{
                __html: data.recH8w67fOGLNkN0w.data.text.childMarkdownRemark.html,
                }} />
                <div className="mt-5 text-center" dangerouslySetInnerHTML={{
                __html: data.rechfPzujFLAG7Irj.data.text.childMarkdownRemark.html,
                }} />
                </Slide>

                <Fade right>
                <div className="row">
                    <div className="col-lg-6 mb-lg-0 mb-3">
                    <Img fixed={data.rectWTEgJjQ6udIno.data.file.localFiles[0].childImageSharp.fixed} alt={data.rectWTEgJjQ6udIno.data.alt} className="w-100" />
                    <div className="mt-2" style={{fontSize:`90%`}} dangerouslySetInnerHTML={{
                    __html: data.rectWTEgJjQ6udIno.data.text.childMarkdownRemark.html,
                    }} />
                    </div>
                    <div className="col-lg-6 story">
                    <div dangerouslySetInnerHTML={{
                    __html: data.recQnAxDBMFSmmc0C.data.text.childMarkdownRemark.html,
                    }} />
                    </div>
                </div>
                </Fade>
                <Fade left>
                <div className="row">
                    <div className="col-lg-6 col-12">
                        <div>
                            <div>
                                <Img fixed={data.recuzr00Xv9EI9B7t.data.file.localFiles[0].childImageSharp.fixed} alt={data.recuzr00Xv9EI9B7t.data.alt} className="w-100" />
                            </div>
                            <div className="mt-2" style={{fontSize:`90%`}} dangerouslySetInnerHTML={{
                            __html: data.recuzr00Xv9EI9B7t.data.text.childMarkdownRemark.html,
                            }} />
                        </div>
                    </div>
                    <div className="col-lg-6 col-12">
                        <div>
                            <div>
                                <Img fixed={data.rec3UMTM9hwcWbJi9.data.file.localFiles[0].childImageSharp.fixed} alt={data.rec3UMTM9hwcWbJi9.data.alt} className="w-100" />
                            </div>
                            <div className="mt-2" style={{fontSize:`90%`}} dangerouslySetInnerHTML={{
                            __html: data.rec3UMTM9hwcWbJi9.data.text.childMarkdownRemark.html,
                            }} />
                        </div>
                    </div>
                </div>
                </Fade>
                </section>

                {/* Sarah Schuster */}
                <section>
                <Slide left>
                <div className="mt-5 text-center" dangerouslySetInnerHTML={{
                __html: data.recDYg4fNRVYyr2Pd.data.text.childMarkdownRemark.html,
                }} />
                <div className="mt-5 text-center" dangerouslySetInnerHTML={{
                __html: data.recrnVALisI8iHfDd.data.text.childMarkdownRemark.html,
                }} />
                </Slide>
                </section>
            </div>
            <div className="container mb-4 story">
                <section>
                <div className="col-lg-8 col-12 mx-auto">
                <Img fluid={data.recyxSRF1xqfMyaIE.data.file.localFiles[0].childImageSharp.fluid} alt={data.recyxSRF1xqfMyaIE.data.alt} />
                <div className="mt-2" style={{fontSize:`90%`}} dangerouslySetInnerHTML={{
                __html: data.recyxSRF1xqfMyaIE.data.text.childMarkdownRemark.html,
                }} />
                </div>

                <blockquote className="my-5 pullquote">
                <p className="mb-0">{data.recUgRu27ULvtaLZZ.data.title}</p>
                <footer className="pullquote-footer">{data.recUgRu27ULvtaLZZ.data.text.childMarkdownRemark.rawMarkdownBody}</footer>
                </blockquote>

                <Slide bottom>
                <div className="col-lg-8 col-12 mx-auto">
                <Img fluid={data.recGgDqWOkJRVjtNR.data.file.localFiles[0].childImageSharp.fluid} alt={data.recGgDqWOkJRVjtNR.data.alt} />
                <div className="mt-2" style={{fontSize:`90%`}} dangerouslySetInnerHTML={{
                __html: data.recGgDqWOkJRVjtNR.data.text.childMarkdownRemark.html,
                }} />
                <Img fluid={data.recMlui1oLvDSCj5r.data.file.localFiles[0].childImageSharp.fluid} alt={data.recMlui1oLvDSCj5r.data.alt} />
                <div className="mt-2" style={{fontSize:`90%`}} dangerouslySetInnerHTML={{
                __html: data.recMlui1oLvDSCj5r.data.text.childMarkdownRemark.html,
                }} />
                </div>
                </Slide>

                </section>

                {/* Pipo Nguyen-Duy */}
                <section>
                <Slide left>
                <div className="mt-5 text-center" dangerouslySetInnerHTML={{
                __html: data.recauOv66Gf1pPYQf.data.text.childMarkdownRemark.html,
                }} />
                <blockquote className="my-5 pullquote">
                <p className="mb-0">{data.reczIcUFxwqaAKJDh.data.title}</p>
                <footer className="pullquote-footer">{data.reczIcUFxwqaAKJDh.data.text.childMarkdownRemark.rawMarkdownBody}</footer>
                </blockquote>
                <div className="mt-5" dangerouslySetInnerHTML={{
                __html: data.recmTHpFn1BSS6DPz.data.text.childMarkdownRemark.html,
                }} />

                <div className="row no-gutters">
                    {data.grid.nodes.map(node => (
                        <Fade key={node.recordId}>
                        <div className="col-lg-6 col-6">
                            <Img fluid={node.data.file.localFiles[0].childImageSharp.fluid} alt={node.data.alt} className="m-1" />
                            <div className="mt-2" style={{fontSize:`90%`}} dangerouslySetInnerHTML={{
                            __html: node.data.text.childMarkdownRemark.html,
                            }} />
                        </div>
                        </Fade>
                    ))}
                </div>
                </Slide>
                </section>
            </div>
            <section>
                <div style={credit} role="img" aria-label={data.recDdlyyGbgge4hsI.data.alt} className="py-5 mb-0 text-center">
                    <div className="credit-text">
                        <p className="h3 credit-title">{data.recDdlyyGbgge4hsI.data.title}</p>
                        <div dangerouslySetInnerHTML={{
                        __html: data.recDdlyyGbgge4hsI.data.text.childMarkdownRemark.html,
                        }} />
                    </div>
                </div>
            </section>
        </main>
        </Layout>
    </div>
)

export const query = graphql`
{
    meta: airtable(recordId: {eq: "recugHAagLDA7uRYd"}) {
        data {
          file {
            localFiles {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          alt
          story
          metaurl
          description
        }
      }

    title: airtable(data: {identifier: {eq: "recLaWFw5HbngYrTK"}}) {
        data {
            title
        }
    }

    subtitle: airtable(data: {identifier: {eq: "recGZEC7UmW54MjPv"}}) {
        data {
            title
        }
    }

    intro: airtable(data: {identifier: {eq: "rec9Kq3HP4SoUhjSJ"}}) {
        data {
            text {
                childMarkdownRemark {
                    html
                }
            }
        }
    }

    recP18OBMUprIGl8U: airtable(data: {identifier: {eq: "recP18OBMUprIGl8U"}}) {
        data {
            text {
                childMarkdownRemark {
                    html
                }
            }
        }
    }

    rec53LvzAhOPcvMim: airtable(data: {identifier: {eq: "rec53LvzAhOPcvMim"}}) {
        data {
            text {
                childMarkdownRemark {
                    html
                }
            }
        }
    }

    recC80LQbomuCLTsh: airtable(data: {identifier: {eq: "recC80LQbomuCLTsh"}}) {
        data {
            text {
                childMarkdownRemark {
                    html
                }
            }
        }
    }

    recdjeiGgrKdONzGe: airtable(recordId: {eq: "recdjeiGgrKdONzGe"}) {
        data {
            alt
            text {
                childMarkdownRemark {
                    html
                }
            }
        }
    }

    recRmJDn4GDeP4jeq: airtable(data: {identifier: {eq: "recRmJDn4GDeP4jeq"}}) {
        data {
            text {
                childMarkdownRemark {
                    html
                }
            }
        }
    }

    recxJ57Zw66DGj62V: airtable(data: {identifier: {eq: "recxJ57Zw66DGj62V"}}) {
        data {
            file {
                localFiles {
                    childImageSharp {
                        fixed {
                            ...GatsbyImageSharpFixed
                        }
                    }
                }
            }
            text {
                childMarkdownRemark {
                    html
                }
            }
            alt
        }
    }

    rec1ljtlkWeLlNIaS: airtable(data: {identifier: {eq: "rec1ljtlkWeLlNIaS"}}) {
        data {
            file {
                localFiles {
                    childImageSharp {
                        fixed {
                            ...GatsbyImageSharpFixed
                        }
                    }
                }
            }
            text {
                childMarkdownRemark {
                    html
                }
            }
            alt
        }
    }

    rec4o1GYKZXekJDft: airtable(data: {identifier: {eq: "rec4o1GYKZXekJDft"}}) {
        data {
            title
            url
        }
    }

    recXWUvWeUXvQo4zy: airtable(data: {identifier: {eq: "recXWUvWeUXvQo4zy"}}) {
        data {
            text {
                childMarkdownRemark {
                    html
                }
            }
        }
    }

    rec6Gh0DX4zW1NF4Q: airtable(data: {identifier: {eq: "rec6Gh0DX4zW1NF4Q"}}) {
        data {
            text {
                childMarkdownRemark {
                    html
                }
            }
        }
    }

    recpWuIxg2LGbUjI3: airtable(data: {identifier: {eq: "recpWuIxg2LGbUjI3"}}) {
        data {
            file {
                localFiles {
                    childImageSharp {
                        fluid(maxHeight: 800, maxWidth: 1200, cropFocus: ATTENTION, quality: 100) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
            alt
            text {
                childMarkdownRemark {
                    html
                }
            }
        }
    }

    recvWC5WK8VuXTefD: airtable(data: {identifier: {eq: "recvWC5WK8VuXTefD"}}) {
        data {
            file {
                localFiles {
                    childImageSharp {
                        fluid(maxHeight: 800, maxWidth: 1200, cropFocus: ATTENTION, quality: 100) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
            alt
            text {
                childMarkdownRemark {
                    html
                }
            }
        }
    }

    recOrYYk9AGpGFTBE: airtable(data: {identifier: {eq: "recOrYYk9AGpGFTBE"}}) {
        data {
            file {
                localFiles {
                    childImageSharp {
                        fluid(maxHeight: 800, maxWidth: 1200, cropFocus: ATTENTION, quality: 100) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
            alt
            text {
                childMarkdownRemark {
                    html
                }
            }
        }
    }

    recgSPOL9WmumrL1k: airtable(data: {identifier: {eq: "recgSPOL9WmumrL1k"}}) {
        data {
            text {
                childMarkdownRemark {
                    html
                }
            }
        }
    }

    recgNHzb1UksEvLs3: airtable(data: {identifier: {eq: "recgNHzb1UksEvLs3"}}) {
        data {
            title
            text {
                childMarkdownRemark {
                    rawMarkdownBody
                }
            }
        }
    }

    recJsNKR1Fsct8w5j: airtable(recordId: {eq: "recJsNKR1Fsct8w5j"}) {
        data {
            alt
            file {
                localFiles {
                    childImageSharp {
                        fixed(height: 400, cropFocus: ATTENTION, quality: 85) {
                            ...GatsbyImageSharpFixed
                        }
                    }
                }
            }
            text {
                childMarkdownRemark {
                    html
                }
            }
        }
    }

    recbhnZ79sJb5UNW0: airtable(recordId: {eq: "recbhnZ79sJb5UNW0"}) {
        data {
            alt
            file {
                localFiles {
                    childImageSharp {
                        fixed(height: 400, cropFocus: ATTENTION, quality: 85) {
                            ...GatsbyImageSharpFixed
                        }
                    }
                }
            }
            text {
                childMarkdownRemark {
                    html
                }
            }
        }
    }

    reciXH91PNmx095aP: airtable(recordId: {eq: "reciXH91PNmx095aP"}) {
        data {
            alt
            file {
                localFiles {
                    childImageSharp {
                        fixed(height: 400, cropFocus: ATTENTION, quality: 85) {
                            ...GatsbyImageSharpFixed
                        }
                    }
                }
            }
            text {
                childMarkdownRemark {
                    html
                }
            }
        }
    }

    rec3OuT8NchYf46zc: airtable(data: {identifier: {eq: "rec3OuT8NchYf46zc"}}) {
        data {
            text {
                childMarkdownRemark {
                    html
                }
            }
        }
    }

    rec3OwCZIGFPmXRNa: airtable(data: {identifier: {eq: "rec3OwCZIGFPmXRNa"}}) {
        data {
            text {
                childMarkdownRemark {
                    html
                }
            }
        }
    }

    recN2r4XNwuASVU1y: airtable(recordId: {eq: "recN2r4XNwuASVU1y"}) {
        data {
            alt
            text {
                childMarkdownRemark {
                    html
                }
            }
        }
    }

    recYkHML3kTyEG9bz: airtable(data: {identifier: {eq: "recYkHML3kTyEG9bz"}}) {
        data {
            text {
                childMarkdownRemark {
                    html
                }
            }
        }
    }

    reccsbPzKH6fZGaA0: airtable(data: {identifier: {eq: "reccsbPzKH6fZGaA0"}}) {
        data {
            file {
                localFiles {
                    childImageSharp {
                        fluid(maxHeight: 800, maxWidth: 1200, cropFocus: ATTENTION, quality: 100) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
            alt
            text {
                childMarkdownRemark {
                    html
                }
            }
        }
    }

    recTxUtLrlpf2rzw4: airtable(data: {identifier: {eq: "recTxUtLrlpf2rzw4"}}) {
        data {
            file {
                localFiles {
                    childImageSharp {
                        fluid(maxHeight: 800, maxWidth: 1200, cropFocus: ATTENTION, quality: 100) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
            alt
            text {
                childMarkdownRemark {
                    html
                }
            }
        }
    }

    recH8w67fOGLNkN0w: airtable(data: {identifier: {eq: "recH8w67fOGLNkN0w"}}) {
        data {
            text {
                childMarkdownRemark {
                    html
                }
            }
        }
    }

    rechfPzujFLAG7Irj: airtable(data: {identifier: {eq: "rechfPzujFLAG7Irj"}}) {
        data {
            text {
                childMarkdownRemark {
                    html
                }
            }
        }
    }

    rectWTEgJjQ6udIno: airtable(recordId: {eq: "rectWTEgJjQ6udIno"}) {
        data {
            alt
            file {
                localFiles {
                    childImageSharp {
                        fixed(height: 550, cropFocus: ATTENTION) {
                            ...GatsbyImageSharpFixed
                        }
                    }
                }
            }
            text {
                childMarkdownRemark {
                    html
                }
            }
        }
    }

    recQnAxDBMFSmmc0C: airtable(data: {identifier: {eq: "recQnAxDBMFSmmc0C"}}) {
        data {
            text {
                childMarkdownRemark {
                    html
                }
            }
        }
    }

    recuzr00Xv9EI9B7t: airtable(data: {identifier: {eq: "recuzr00Xv9EI9B7t"}}) {
        data {
            file {
                localFiles {
                    childImageSharp {
                        fixed(height: 550, cropFocus: ATTENTION) {
                            ...GatsbyImageSharpFixed
                        }
                    }
                }
            }
            text {
                childMarkdownRemark {
                    html
                }
            }
            alt
        }
    }

    rec3UMTM9hwcWbJi9: airtable(data: {identifier: {eq: "rec3UMTM9hwcWbJi9"}}) {
        data {
            file {
                localFiles {
                    childImageSharp {
                        fixed(height: 350, cropFocus: ATTENTION) {
                            ...GatsbyImageSharpFixed
                        }
                    }
                }
            }
            text {
                childMarkdownRemark {
                    html
                }
            }
            alt
        }
    }

    recDYg4fNRVYyr2Pd: airtable(data: {identifier: {eq: "recDYg4fNRVYyr2Pd"}}) {
        data {
            text {
                childMarkdownRemark {
                    html
                }
            }
        }
    }

    recrnVALisI8iHfDd: airtable(data: {identifier: {eq: "recrnVALisI8iHfDd"}}) {
        data {
            text {
                childMarkdownRemark {
                    html
                }
            }
        }
    }

    recyxSRF1xqfMyaIE: airtable(data: {identifier: {eq: "recyxSRF1xqfMyaIE"}}) {
        data {
            file {
                localFiles {
                    childImageSharp {
                        fluid(maxHeight: 960, maxWidth: 1200, cropFocus: ATTENTION, quality: 100) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
            text {
                childMarkdownRemark {
                    html
                }
            }
            alt
        }
    }

    recUgRu27ULvtaLZZ: airtable(data: {identifier: {eq: "recUgRu27ULvtaLZZ"}}) {
        data {
            title
            text {
                childMarkdownRemark {
                    rawMarkdownBody
                }
            }
        }
    }

    recGgDqWOkJRVjtNR: airtable(data: {identifier: {eq: "recGgDqWOkJRVjtNR"}}) {
        data {
            file {
                localFiles {
                    childImageSharp {
                        fluid(maxHeight: 1200, maxWidth: 1200, cropFocus: ATTENTION, quality: 100) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
            alt
            text {
                childMarkdownRemark {
                    html
                }
            }
        }
    }
    
    recMlui1oLvDSCj5r: airtable(data: {identifier: {eq: "recMlui1oLvDSCj5r"}}) {
        data {
            file {
                localFiles {
                    childImageSharp {
                        fluid(maxHeight: 806, maxWidth: 1200, cropFocus: ATTENTION, quality: 100) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
            alt
            text {
                childMarkdownRemark {
                    html
                }
            }
        }
    }

    recauOv66Gf1pPYQf: airtable(recordId: {eq: "recauOv66Gf1pPYQf"}) {
        data {
            alt
            text {
                childMarkdownRemark {
                    html
                }
            }
        }
    }

    reczIcUFxwqaAKJDh: airtable(data: {identifier: {eq: "reczIcUFxwqaAKJDh"}}) {
        data {
            title
            text {
                childMarkdownRemark {
                    rawMarkdownBody
                }
            }
        }
    }

    recmTHpFn1BSS6DPz: airtable(recordId: {eq: "recmTHpFn1BSS6DPz"}) {
        data {
            alt
            text {
                childMarkdownRemark {
                    html
                }
            }
        }
    }

    grid: allAirtable(filter: {data: {element_type: {eq: "grid"}, story: {eq: "Faculty Virtual Art Show"}}}) {
        nodes {
            recordId
            data {
                file {
                    localFiles {
                        childImageSharp {
                            fluid(cropFocus: ATTENTION, maxHeight: 500, maxWidth: 500) {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                }
                alt
                text {
                    childMarkdownRemark {
                        html
                    }
                }
            }
        }
    }

    recDdlyyGbgge4hsI: airtable(recordId: {eq: "recDdlyyGbgge4hsI"}) {
        data {
            alt
            title
            text {
                childMarkdownRemark {
                    html
                }
            }
        }
    }
}  
`;